<template>
	<div class="study">
		<van-nav-bar
			title="米因研究"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<van-list
		  v-model="loading"
		  :finished="finished"
		  finished-text="没有更多了"
		  :offset='50'
		  @load="onLoad"
		>
		  <ul> 
			  <li 
				class="list qjc-clearfix"
				v-for="item in list"
				:key="item.id"
				@click="$router.push('/study/detail/'+item.id)"
			  >
				  <dl class="qjc-fl qjc-texta-l">
					  <dt class="qjc-fts-32 qjc-c-dark qjc-ftw-b">{{ item.title }}</dt>
					  <dd class="qjc-fts-24">
						  <span>
							  <van-image
								width="0.32rem"
								height="0.23rem"
								:src="require('@/assets/images/check_eye.png')"
							  />
							  <span>{{ item.read }}</span>
						  </span>
						  <span>
							  <van-image
								width="0.24rem"
								height="0.24rem"
								:src="require('@/assets/images/create_time.png')"
							  />
							  <span>{{ item.created_at }}</span>
						  </span>
					  </dd>
				  </dl>
				  <van-image
					width="1.8rem"
					height="1.35rem"
					class="qjc-fr"
					:src="item.pic"
				  />
			  </li>
		  </ul>
		</van-list>
		
		<!-- 底部导航 -->
		<van-tabbar 
			v-model="navIcon.active"
			active-color="#6681FA"
			inactive-color="#9DA6B9"
			safe-area-inset-bottom
		>
			<van-tabbar-item name="home" to="/">
				<span>首頁</span>
				<img 
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.home.active:navIcon.home.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="study" to="/study">
				<span>米因研究</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.study.active:navIcon.study.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="learn" to="/learn">
				<span>學術中心</span>
				<img
					style="width: 0.37rem;height: 0.43rem;"
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.learn.active:navIcon.learn.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="user" :to="isPerfectInfo ? '/user' : '/regist/perfectInfo'">
				<span>我的</span>
				<img
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.user.active:navIcon.user.normal"
				>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Tabbar, TabbarItem, List, Image } from 'vant'
	Vue.use(NavBar)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(List)
	   .use(Image);
	
	import { studyList } from '@/js/axios'
	
	export default{
		name: 'study',
		data (){
			return {
				page: 1,//默認第一頁
				pagesize: 10,//默認一頁10條數據
				list: [],
				loading: false,
				finished: false,
				navIcon: {
					active: 'study',
					home: {
						normal: require('@/assets/images/shouye1.png'),
						active: require('@/assets/images/shouye.png')
					},
					study: {
						normal: require('@/assets/images/study.png'),
						active: require('@/assets/images/study_active.png')
					},
					learn: {
						normal: require('@/assets/images/learn.png'),
						active: require('@/assets/images/learn-active.png')
					},
					user: {
						normal: require('@/assets/images/wode.png'),
						active: require('@/assets/images/wode2.png')
					}
				},
				isPerfectInfo: true,
			}
		},
		mounted() {
			if(this.$store.state.isLogin) {
				let userInfo = JSON.parse(localStorage.getItem('user'))
				if(userInfo && userInfo.birth != '' && userInfo.sex != 0) {
					this.isPerfectInfo = true
				} else {
					this.isPerfectInfo = false
				}	
			}
			
		},
		methods: {
			
			onLoad() {
				// 异步更新数据
				setTimeout(() => {
					
					studyList({
						page: this.page,
						pagesize: this.pagesize
					}).then(res => {
						// console.log(res);
						var page = res.data.pager,
							list = res.data.data;
					
						this.list = this.list.concat(list);
						
						// 加载状态结束
						this.loading = false;
						
						// 数据全部加载完成
						if (this.page >= page.pagecount) {
						  this.finished = true;
						}else{
							++this.page;
						}
					}, 500);
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.study{
		padding-top: 0.88rem;
		
		.list{
			color: #A1A7B2;
			margin: 0 0.24rem;
			padding: 0.39rem 0;
			border-bottom: 0.01rem solid #DCDEE5;
			
			.van-image{
				vertical-align: middle;
			}
			dl{
				width: 4.9rem;
				
				dt{
					height: 0.88rem;
					line-height: 0.44rem;
					margin-bottom: 0.24rem;
				}
				dd{
					line-height: 0.24rem;
					
					span{
						margin-right: 0.46rem;
						
						span{
							vertical-align: middle;
							margin-left: 0.09rem;
						}
					}
				}
			}
		}
		.van-tabbar{
			height: 0.98rem;
			
			span{
				font-size: 0.2rem;
			}
			img{
				width: 0.43rem;
				height: 0.44rem;
			}
		}
	}
</style>
